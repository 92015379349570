import React from 'react';

function Info() {
    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">
                    <div className="card-header">
                        <h2>Pricing</h2>
                    </div>
                    <div className="card-body">
                        <h2>Home Information Storage</h2>
                        <h4>Single User Plan</h4>
                        <blockquote className="blockquote mb-0">
                            $4.99/month<br/>
                            $50/year
                        </blockquote>
                        <br />
                        <h2>Consultation</h2>
                        <blockquote className="blockquote mb-0">
                            Includes phone calls, FaceTime/Zoom, review of quotes, invoices, and photos. <br/> <br/>
                            $25 per 15 minutes (minimum)<br/>
                            $150 first hour<br/>
                            $100 each additional hour<br/>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info;