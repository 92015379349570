import React from 'react';

function About() {
    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">
                    <div className="card-header">
                        <h2>About</h2>
                    </div>
                    <div className="card-body">
                        As a licensed builder, Doug saw a need for homeowners to store information about their homes.
                        What paint color did we use in the kids' bedroom? What faucet did we install in the kitchen?
                        Is my refrigerator still under warranty? Instead of searching through the garage or files full of receipts,
                        once entered, the homeowner can access all their home's information in <strong>one convenient space.</strong>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;