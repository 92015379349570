import {React, useRef, useState, useEffect} from 'react';
import useAuth from '../hooks/useAuth';
import axios from '../api/axios';

import { useNavigate, useLocation } from 'react-router-dom';

const LOGIN_URL = '/login';

const Login = () => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState("");
    const [pwd, setPwd] = useState("");
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    //const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({user, pwd}),
                {
                    headers: {'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            setUser('');
            setPwd('');
            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;
            
            console.log('roles:', roles);
            setAuth({user, roles, accessToken})
            navigate(from, {replace: true});
        }
        catch (err) {
            console.log(err);
            if (!err?.response) {
                setErrMsg('No server response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing user/pass');
            } else if (err.response?.status === 401) {
                setErrMsg('Invalid Username or Password');
            } else {
                setErrMsg('Login failure');
            }
            errRef.current.focus();
        }
    }

    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">
                    <div className="card-header">
                        <h2>User Login</h2>
                    </div>
                    
                    <div className="card-body">
                        <p ref={errRef} className={errMsg ? "alert alert-ad-danger":"offscreen"} aria-live="assertive">{errMsg}</p>
                        
                        <form className="row g-3" onSubmit={handleSubmit}>
                            <div className="col-md-6">
                                <label htmlFor="inputUsername" autoComplete="username" name="username" className="form-label">Username or Email</label>
                                <input
                                    // type="email"
                                    type="text"
                                    ref={userRef}
                                    className="form-control"
                                    id="inputUsername"
                                    value={user}
                                    required
                                    onChange={(e) => setUser(e.target.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputPassword" autoComplete="current-password" name="password" className="form-label">Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="inputPassword"
                                    name="password"
                                    value={pwd}
                                    required
                                    onChange={(e) => setPwd(e.target.value)}
                                />
                            </div>

                            <div className="form-check offscreen">
                                <input type="checkbox" className="form-check-input" id="rememberMe" />
                                <label className="form-check-label" htmlFor="rememberMe" >Remember Me (UNIMPLEMENTED)</label>
                            </div>
                            <button type="submit" className="btn btn-ad-primary">Login</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;