import React from 'react';
import { useNavigate } from 'react-router-dom';

function Unauthorized() {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">
                    <div className="card-header">
                        <h2>Unauthorized</h2>
                    </div>
                    <div className="card-body">
                        <blockquote className="blockquote mb-0">
                            <p>You don't have permissions to do that with your account privileges.</p>
                        </blockquote>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-ad-primary" onClick={goBack}>Go Back</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Unauthorized;