import axios from 'axios';

export default axios.create({
    baseURL: 'https://test.askdoug.pro'  // PRODUCTION

    /*
    baseURL: 'http://localhost:3000'  // DEVELOPMENT
    baseURL: 'https://test.askdoug.pro'  // PRODUCTION


    // ---- package.json - proxy: ----
    
    // DEVELOPMENT
    "proxy": "http://localhost:5000",

    // PRODUCTION
    "proxy": "https://test.askdoug.pro",
    
    // ---- db_sql: ----
    
    host: "192.168.1.145",    // DEVELOPMENT
    user: "doug_react",       // DEVELOPMENT
    
    host: "localhost",        // PRODUCTION
    user: "doug_localhost",   // PRODUCTION

    //$x6n5G33l
    */
})