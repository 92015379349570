import React from 'react';

import {useRef, useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";

import axios from '../api/axios';
import useAuth from "../hooks/useAuth";

const ROLES = require('../config/roles');

const PWD_REGEX = /^.{8,}/;

const FETCH_ACCOUNT_URL = '/myaccount';
const UPDATE_ACCOUNT_URL = '/myaccount';
const CHANGE_PASSWORD_URL = '/changepass';

function Account() {
    // Set focus for error
    const alertRef = useRef();
    
    const { auth } = useAuth();

    const navigate = useNavigate();

    const [inputs, setInputs] = useState({});

    const [currentPass, setCurrentPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [passValid, setPassValid] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPass, setMatchPass] = useState('');
    const [matchValid, setMatchValid] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [dirtyInputs, setDirtyInputs] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [userRoles, setUserRoles] = useState([ROLES.User]);

    useEffect(() => {
        const result = PWD_REGEX.test(newPass);
        setPassValid(result);
        const match = newPass === matchPass;
        setMatchValid(match);
    }, [newPass, matchPass]);

    useEffect(() => {
        setErrMsg('');
    }, [newPass, matchPass, inputs]);

    const updateInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setDirtyInputs(true);
        setInputs(values => ({...values, [name]: value}));
    };

    // Fetch the account details to populate the form
    useEffect(() => {
        try {
            axios.get(FETCH_ACCOUNT_URL,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`
                },
                withCredentials: true
            }
            ).then(
                response => {
                    const userData = response?.data;
                    console.log(userData);
                    setInputs({
                        user: userData.username ?? '',
                        email: userData.email ?? '',
                        phone: userData.phone ?? '',
                        first: userData.firstName ?? '',
                        last: userData.lastName ?? '',
                        company: userData.company ?? '',
                        address: userData.address ?? '',
                        address2: userData.address2 ?? '',
                        city: userData.city ?? '',
                        state: userData.state ?? '',
                        zip: userData.zip ?? ''
                    });

                    if (userData?.roles) {
                        setUserRoles(JSON.parse(userData.roles));
                    }
                }
            );
        }
        catch (err) {
            console.log(err);
        }
    }, [auth]);

    const updateAccount = async (e) => {
        e.preventDefault();

        // Update account data
        try {
            await axios.post(UPDATE_ACCOUNT_URL,
                JSON.stringify({inputs}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
            );
            setDirtyInputs(false);
            setSuccess(true);
        }   
        catch (err) {
            if (!err?.response) {
                setErrMsg('No server response');
            } else if (err.response?.status === 409) {
                setErrMsg(`Unable to update account: ${err.response?.data}`);
            }
            else {
                console.log(err)
                setErrMsg('Account update failure: ', JSON.stringify(err));
            }
        }
    }

    const changePassword = async (e) => {
        e.preventDefault();

        // Update account data
        try {
            await axios.post(CHANGE_PASSWORD_URL,
                JSON.stringify({currentPass, newPass}),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.accessToken}`
                    },
                    withCredentials: true
                }
            ).then( response => {
                console.log(response);
                setSuccess(true);
                setCurrentPass("");
                setNewPass("");
                setMatchPass("");
            });
            
        }   
        catch (err) {
            if (!err?.response) {
                setErrMsg('No server response');
            } else if (err.response?.status === 409) {
                setErrMsg(`Unable to update account: ${err.response?.data}`);
            }
            else {
                console.log(err)
                setErrMsg('Account update failure: ', JSON.stringify(err));
            }
        }
    };

    const getRoleName = (roleId) => {
        return Object.keys(ROLES).find(key => ROLES[key] === roleId);
    };

    const accountRoleAction = (event) => {
        const cplAction = event.target.id;
        if (cplAction === "btnAdmin") {
            navigate('/admin/home');
        }
    }

    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">
                    <div className="card-header">
                        <h2>Edit Account</h2>
                    </div>
                    <div className="card-body">
                        <p ref={alertRef} role="alert" className={errMsg ? "alert alert-ad-danger" : "offscreen"}
                            aria-live="assertive">{errMsg}</p>
                        <p ref={alertRef} role="alert" className={success ? "alert alert-ad-success" : "offscreen"}
                        aria-live="assertive">Account Updated Successfully!</p>

                        <form className="row g-3" onSubmit={updateAccount}>

                            <div className="col-md-4">
                                <label htmlFor="inputUsername" className="form-label">Username</label>
                                <input type="text" id="inputUsername" disabled className="form-control" value={inputs.user || ""}/>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="email" className="form-label">Email Address</label>
                                <input type="email"
                                className="form-control"
                                required
                                name="email"
                                id="email"
                                onChange={updateInput}
                                value={inputs.email || ""} />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="phone" className="form-label">Phone Number</label>
                                <input type="tel"
                                className="form-control"
                                required
                                name="phone"
                                id="phone"
                                onChange={updateInput}
                                value={inputs.phone || ""} />
                            </div>
                            
                            <div className="col-md-6">
                                <label htmlFor="first" className="form-label">First Name</label>
                                <input type="text"
                                className="form-control"
                                required
                                name="first"
                                id="first"
                                onChange={updateInput}
                                value={inputs.first || ""} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="last" className="form-label">Last Name</label>
                                <input type="text"
                                className="form-control"
                                required
                                name="last"
                                id="last"
                                onChange={updateInput}
                                value={inputs.last || ""} />
                            </div>

                            <div className="col-12">
                                <label htmlFor="company" className="form-label">Company (optional)</label>
                                <input type="text"
                                className="form-control"
                                name="company"
                                id="company"
                                onChange={updateInput}
                                value={inputs.company || ""} />
                            </div>

                            <div className="col-12">
                                <label htmlFor="address" className="form-label">Address</label>
                                <input type="text"
                                className="form-control"
                                required
                                name="address" 
                                id="address"
                                onChange={updateInput}
                                value={inputs.address || ""} />
                            </div>

                            <div className="col-12">
                                <label htmlFor="address2" className="form-label">Address 2</label>
                                <input type="text"
                                className="form-control"
                                name="address2"
                                id="address2"
                                placeholder="Apartment, studio, or floor"
                                onChange={updateInput}
                                value={inputs.address2 || ""} />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="city" className="form-label">City</label>
                                <input type="text"
                                className="form-control"
                                required
                                name="city"
                                id="city"
                                onChange={updateInput}
                                value={inputs.city || ""} />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="state" className="form-label">State</label>
                                <input type="text"
                                className="form-control"
                                required
                                name="state"
                                id="state"
                                onChange={updateInput}
                                value={inputs.state || ""} />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="zip" className="form-label">Zip</label>
                                <input type="text"
                                className="form-control"
                                required
                                name="zip"
                                id="zip"
                                onChange={updateInput}
                                value={inputs.zip || ""} />
                            </div>

                            <div className="col-12">
                                <button disabled={!dirtyInputs} type="submit" className="btn btn-ad-primary">Update Account</button>
                            </div>

                        </form>

                        <br />
                        <br />
                        <h3>Change Password</h3>

                        <form className="row g-3" onSubmit={changePassword}>
                            <div className="col-md-4">
                                <label htmlFor="currentPassword" className="form-label">Current Password</label>
                                <input 
                                    type="password" className="form-control" id="currentPassword" 
                                    onChange={(e) => setCurrentPass(e.target.value)} required
                                    value={currentPass}
                                />
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="inputPassword" className="form-label">New Password</label>
                                <span className={passValid ? "valid" : "offscreen"}> ✔</span>
                                <span className={passValid || !newPass ? "offscreen" : "invalid"}> ❌</span>
                                <input 
                                    type="password"
                                    className={passValid || !newPass ? "form-control" : "form-control is-invalid"}
                                    id="inputPassword" 
                                    onChange={(e) => setNewPass(e.target.value)}
                                    value={newPass}
                                    required
                                    aria-invalid={passValid ? "false" : true}
                                    aria-describedby="pwdnote"
                                    onFocus={() => setPwdFocus(true)}
                                    onBlur={() => setPwdFocus(false)}
                                />
                                <p id="pwdnote" className={pwdFocus && !passValid ? "invalid-feedback" : "offscreen"}>
                                    Must be at least 8 characters.
                                </p>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="inputPasswordConfirm" className="form-label">Confirm New Password</label>
                                <span className={matchValid && matchPass ? "valid" : "offscreen"}> ✔</span>
                                <span className={matchValid || !newPass ? "offscreen" : "invalid"}> ❌</span>
                                <input 
                                    type="password"
                                    className={matchValid || !newPass ? "form-control" : "form-control is-invalid"}
                                    id="inputPasswordConfirm" 
                                    onChange={(e) => setMatchPass(e.target.value)}
                                    required
                                    value={matchPass}
                                    aria-invalid={matchValid ? "false" : true}
                                    aria-describedby="confirmnote"
                                    onFocus={() => setMatchFocus(true)}
                                    onBlur={() => setMatchFocus(false)}
                                />
                                <p id="confirmnote" className={matchFocus && !matchValid ? "invalid-feedback" : "offscreen"}>
                                    Passwords must match.
                                </p>
                                
                            </div>

                            <div className="col-12">
                                <button disabled={!currentPass || !passValid || !matchValid} type="submit" className="btn btn-ad-warning">Change Password</button>
                            </div>
                        </form>

                        
                        <br />
                        <h3>Account Roles</h3>
                        {userRoles.map(role => {
                            // return the role name for each of the user's roles
                            const roleName = getRoleName(role);
                            return <button key={role} id={'btn'+roleName} onClick={accountRoleAction} className='btn btn-outline-primary'>{roleName}</button>;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Account;