import React from 'react';

import logo from '../images/logo.png';

function Header() {
    return (
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
                Welcome to <code className='brand-secondary'>test</code><span className="brand-primary">.askdoug.pro</span>
            </p>
        </header>
    );
}

export default Header;