import { React, useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';

import axios from '../../api/axios';

// import {useRef, useState, useEffect} from "react";

import useAuth from "../../hooks/useAuth";

// const ROLES = require('../config/roles');

// const FETCH_ACCOUNT_URL = '/myaccount';
const ROOM_TYPES_URL = '/roomtypes';
const CREATE_ROOM_TYPE_URL = '/roomtype';

function AdminHome() {

    // React hooks
    const navigate = useNavigate();
    const { auth } = useAuth();
    const alertRef = useRef();

    const [roomTypes, setRoomTypes] = useState(null);
    const [selectedRoomType, setSelectedRoomType] = useState(null);
    
    const [newRoom, setNewRoom] = useState(null);
    const [alertRegion, setAlertRegion] = useState(null);


    const GoToAdminPage = (event) => {
        const page = event?.target?.name;
        if (page && selectedRoomType) {
            navigate('/admin/' + page + '?roomTypeID=' + selectedRoomType);
        }
    }

    // Get room types at render and store in effect hook
    useEffect(() => {
        try {
            axios.get(ROOM_TYPES_URL,
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            ).then(
                response => {
                    const roomMap = response?.data?.rooms.map(room => (
                        { label: room.roomTypeName, value: room.roomTypeID }
                    ));
                    setRoomTypes(roomMap);
                }
            );
        }
        catch (err) {
            console.log(err);
        }
    }, [alertRegion]);

    const updateRoomName = (event) => {
        console.log(event.target.value);
        setNewRoom(event.target.value);
    }

    const createRoom = () => {
        axios.put(CREATE_ROOM_TYPE_URL,
            JSON.stringify({roomType: newRoom}),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`
                },
                withCredentials: true
            }
        ).then(function (response) {
            if (response?.status === 201) {
                setAlertRegion(<p ref={alertRef} className="alert alert-ad-success">Created new room type "{newRoom}"</p>)
            }
        }).catch(function (err) {
            if (err?.response?.status === 409) {
                setAlertRegion(<p ref={alertRef} className="alert alert-ad-warning">Room type "{newRoom}" already exists!</p>)
            }
            else {
                console.log(err);
                setAlertRegion(<p ref={alertRef} className="alert alert-ad-danger">Unable to create room type "{newRoom}" - {err?.message}</p>)
            }
        });
    };

    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">
                    <div className="card-header">
                        <h2>Website Administration</h2>
                    </div>
                    <div className="card-body">

                        {alertRegion}

                        <h3>Create New Room Type</h3>
                        
                            <div class="input-group mb-3">
                                <span className="input-group-text" id="inputGroup-sizing-default">New Room Type:</span>
                                    <input type="text" class="form-control" id="newRoom" name="newRoom" value={newRoom} maxLength="32" onChange={updateRoomName}/>
                                    <button class="input-group-text btn btn-ad-success" disabled={!newRoom} id="inputGroup-sizing-default" onClick={createRoom}>Create Room</button>
                                </div>
                        

                        <hr />
                        <h3>Modify Existing Room Type</h3>
                        <select id="roomType" className="form-select" value={selectedRoomType || ''} onChange={(e) => setSelectedRoomType(e.target.value)}>
                            <option defaultValue value=''>Select a Room Type...</option>
                            {roomTypes && roomTypes.map((room) => (
                                <option key={room.value} value={room.value}>
                                    {room.label}
                                </option>
                            ))}
                        </select>

                        <button className='btn btn-ad-primary m-2' name='editroom' disabled={!selectedRoomType} onClick={GoToAdminPage}>Edit Room</button>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminHome;