import React from 'react';
import {Link} from 'react-router-dom';

import useAuth from "../hooks/useAuth";
import axios from '../api/axios';

// import icon from '../images/icon.png';

const LOGOUT_URL = "/logout";

// Log out the user and navigate to the home page with cleared cookies
const userLogout = async (event) => {
    try {
        await axios.post(LOGOUT_URL);
        window.location.reload();
    }
    catch (err) {
        console.log(err);
    }
}

const GenerateNavbarElements = () => {
    const { auth } = useAuth();

    // Show elements depending on if user is logged in
    if (auth.user) {
        return (
            <>
                <li className="nav-item">
                    <Link to="/selectproject" className="nav-link">My Projects</Link>
                </li>
                <li className="nav-item">
                    <Link to="/about" className="nav-link">About</Link>
                </li>
                <li className="nav-item">
                    <Link to="/help" className="nav-link">Help</Link>
                </li>
                <li className="nav-item">
                    <Link to="/account" className="nav-link text-ad-primary">My Account</Link>
                </li>
                <li>
                    <Link to="/" onClick={userLogout} className="nav-link text-ad-danger">Log Out</Link>
                </li>
            </>
        );
    }
    else {
        return (
            <>
                <li>
                    <Link to="/login" className="nav-link">Log In</Link>
                </li>
                <li className="nav-item">
                    <Link to="/register" className="nav-link">Sign Up</Link>
                </li>
                <li className="nav-item">
                    <Link to="/info" className="nav-link">Pricing</Link>
                </li>
                <li className="nav-item">
                    <Link to="/about" className="nav-link">About</Link>
                </li>
                <li className="nav-item">
                    <Link to="/help" className="nav-link">Help</Link>
                </li>
            </>
        );
    }
}

function NavBar() {
    return (
        
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <Link className="navbar-brand" to="/">
                    {/* <img src={icon} height="32" alt="Ask Doug Logo" className="d-inline-block align-text-top" /> */}
                    &nbsp;&nbsp;&nbsp;&nbsp;Home
                </Link>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <GenerateNavbarElements/>
                    </ul>
                </div>
            </div>
        </nav>

        /*
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">Ask Doug</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">Home</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="#">Link</a>
                    </li>
                    <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Dropdown
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" href="#">Action</a></li>
                        <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><hr className="dropdown-divider"/></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                    </li>
                </ul>
                <form className="d-flex">
                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success" type="submit">Search</button>
                </form>
                </div>
            </div>
            </nav>
            */


    );
}

export default NavBar;