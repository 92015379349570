import React from 'react';

import logo from '../images/logo_buffer.png';

function Home() {
    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">
                    <div className="card-header">
                        <h2>Home</h2>
                    </div>
                    <br />
                    <blockquote className="blockquote mb-0 text-center">
                        <p>All your remodeling material information saved in one place and accessible from anywhere</p>
                        <p>An excellent tool for homeowners, designers, builders, and property managers</p>
                    </blockquote>
                    <hr />
                    <div className="card-body">
                        <p className="card-text">
                            In addition to home information storage, Doug offers consultations to homeowners. With over 20 years of
                            experience in construction, he is a wealth of knowledge and often helps with do-it-yourself projects
                            and small remodeling jobs that may not require a General Contractor.
                        </p>
                        <p className="card-text">
                            For advice on your home improvement project contact Doug at doug@askdoug.pro or 1-760-791-4687
                        </p>
                    </div>
                    <div className="card-footer">
                        <img className="card-img-bottom" src={logo} alt="Ask Doug logo"></img>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;