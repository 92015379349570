import {React, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import axios from '../api/axios';
import useAuth from "../hooks/useAuth";

const PROJECTS_URL = '/projects';

function SelectProject() {
    const navigate = useNavigate();

    const [projectOptions, setProjectOptions] = useState([]);
    const [infoArea, setInfoArea] = useState([]);

    const { auth } = useAuth();

    // Fetch the projects for this user and return the map, or generate a warning that there are none
    useEffect(() => {
        const getProjectMap = async (cb) => {
            try {
                const response = await axios.get(PROJECTS_URL,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${auth.accessToken}`
                        },
                        withCredentials: true
                    }
                );
                // If no projects
                if (response?.status === 204) {
                    return cb(null, []);
                }
                else {
                    const projectMap = response?.data?.projects.map(project => (
                        { label: project.name, value: project.projectID }
                    ));
                    
                    return cb(null, projectMap);
                }
            }
            catch (err) {
                return cb(err);
            }
        };
        const createProject = (event) => {
            event.preventDefault();
            navigate('/projectsetup');
        };
        getProjectMap(function (err, projectOptMap) {
            setInfoArea(
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            );
            setProjectOptions(projectOptMap);
            if (projectOptMap.length === 0) {
                setInfoArea(
                    <p className="alert alert-ad-warning">You haven't created any projects yet. <button className="btn btn-sm btn-ad-success ms-2" type="button" onClick={createProject}>Create New Project</button></p>
                );
            }
            else {
                setInfoArea();
            }
        })
    }, [auth, navigate]);

    const createProject = (event) => {
        event.preventDefault();
        navigate('/projectsetup');
    };

    const openProject = (event) => {
        event.preventDefault();
        navigate(`/projecthome?projectID=${event.target.id}`);
    }

    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">
                    <div className="card-header">
                        
                        <div className="container">
                            <div className="row">
                                <div className="col text-start"></div>
                                <div className="col-6 text-center">
                                    <h2>Project Selection</h2>
                                </div>
                                <div className="col text-end align-self-center">
                                    <button className="btn btn-ad-success" type="button" onClick={createProject}>Create New Project</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        {infoArea}
                        
                        {(projectOptions.length > 0) && 
                        <form className="row g-3">
                            <div className="col-12">
                                <label htmlFor="projectName" className="form-label"><h4>Select Project:</h4></label>
                                <ul className="list-group text-lg">
                                    {projectOptions && (projectOptions.map((project) => (
                                        <li className="list-group-item project-item" key={project.value} id={project.value} onClick={openProject}><h5 id={project.value}>{project.label}</h5></li>
                                    )))}
                                    
                                </ul>
                            </div>
                        </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectProject;