import { useState } from "react";

function Howdy() {
  const [myBool, setmyBool] = useState(true);

  function toggleBool() {
    setmyBool(!myBool)
  }

  return (
    myBool ? <Landing toggleBool={toggleBool} /> : <Marketing /> 
  );
}

function Landing(props){
  return (
    <div>
      <h1>Landing</h1>
      <button onClick={props.toggleBool}>Button</button>
    </div>
  )
}

function Marketing(props){
  return (
    <h1>Marketing</h1>
  )
}

export default Howdy;