import React from 'react';
import { Document, Page } from 'react-pdf';

function PDFViewer({ base64Data, pageNumber, onDocumentLoadSuccess, onPageLoadSuccess }) {  
    return (
        <div>
            {base64Data && (
                <Document
                    wrap={false}
                    file={`data:application/pdf;base64,${base64Data}`}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page
                        pageNumber={pageNumber}
                        width={850}
                        renderTextLayer={false}
                        onLoadSuccess={onPageLoadSuccess}
                        renderAnnotationLayer={false}
                    />
                </Document>
            )}
        </div>
    );
}

export default PDFViewer;