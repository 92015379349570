import React from 'react';

function Help() {
    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">
                    <div className="card-header">
                        <h2>Help</h2>
                    </div>
                    <div className="card-body">

                        <h4>Contact Information</h4>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Phone Number:</div>
                                    +1 (760) 791-4687
                                </div>
                            </li>
                            <li className="list-group-item">
                                {/* https://www.npmjs.com/package/react-obfuscate  - can obfuscate with a plugin */}
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">Customer Service Email:</div>
                                    customerservice@askdoug.pro
                                </div>
                            </li>
                        </ul>
                        
                        <br />
                        <br />

                        <h4>Frequently Asked Questions</h4>
                        <div className="accordion" id="helpAccordian">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Will I get emails from other companies trying to get me to buy things once I sign up for ask Doug?
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#helpAccordian">
                                    <div className="accordion-body">
                                        <strong>No.</strong> We do not sell or transfer any of your information to any third party companies.
                                    </div>
                                </div>
                            </div>
                            
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <code className='brand-secondary' style={{"font-size": "18pt"}}>test:</code>&nbsp;&nbsp;<b>Theme Demo</b>
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#helpAccordian">
                                    <div className="accordion-body">
                                        This page demonstrates common site elements with styling from the current theme.<br />
                                        <button className='btn btn-ad-primary m-2'>Primary Button</button>
                                        <button className='btn btn-ad-success m-2'>Success Button</button>
                                        <button className='btn btn-ad-warning m-2'>Warning Button</button>
                                        <button className='btn btn-ad-danger m-2'>Danger Button</button>
                                        <button className='btn btn-ad-info m-2'>Special Button</button>
                                        <button className='btn btn-ad-secondary m-2'>Button</button>
                                        <br />
                                        <button className='btn btn-ad-primary m-2' disabled>Primary Button</button>
                                        <button className='btn btn-ad-success m-2' disabled>Success Button</button>
                                        <button className='btn btn-ad-warning m-2' disabled>Warning Button</button>
                                        <button className='btn btn-ad-danger m-2' disabled>Danger Button</button>
                                        <button className='btn btn-ad-info m-2' disabled>Special Button</button>
                                        <button className='btn btn-ad-secondary m-2' disabled>Button</button>

                                        <br /><br />

                                        <div class="alert alert-ad-primary" role="alert">
                                            This is a primary alert — it indicates that something significant happened
                                        </div>
                                        <div class="alert alert-ad-secondary" role="alert">
                                            This is a secondary alert — it is neutral and conveys information plainly
                                        </div>
                                        <div class="alert alert-ad-success" role="alert">
                                            This is a success alert — it indicates that a pending action was successful
                                        </div>
                                        <div class="alert alert-ad-danger" role="alert">
                                            This is a danger alert — it indicates that a serious problem has occurred
                                        </div>
                                        <div class="alert alert-ad-warning" role="alert">
                                            This is a warning alert — it indicates that something important requires attention
                                        </div>
                                        <div class="alert alert-ad-info" role="alert">
                                            This is an info alert — it can be used for special announcements
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <code className='brand-secondary' style={{"font-size": "18pt"}}>test:</code>&nbsp;&nbsp;<b>Color Palette</b>
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#helpAccordian">
                                    <div className="accordion-body">
                                        <table>
                                            <thead>
                                                <th className="h3" style={{"text-align":"center"}}>Color</th>
                                                <th className="h3" width="300" style={{"text-align":"center"}}>Tints</th>
                                                <th className="h3" width="300" style={{"text-align":"center"}}>Shades</th>
                                            </thead>
                                        <tbody>
                                            
                                            {/* Dead Sea (Primary) */}
                                            <tr>
                                                <td rowspan="6"><strong>Primary —</strong> Main theme color, used for hyperlinks, focus styles, and component and form active states.</td>
                                                <td colspan="2"><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#72F2EB"}}>Dead Sea (#72F2EB)</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#80F3ED"}}>#80F3ED</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#67DAD3"}}>#67DAD3</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#8EF5EF"}}>#8EF5EF</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#5BC2BC", "color": "#FFF"}}>#5BC2BC</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#9CF6F1"}}>#9CF6F1</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#50A9A4", "color": "#FFF"}}>#50A9A4</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#AAF7F3"}}>#AAF7F3</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#44918D", "color": "#FFF"}}>#44918D</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#B8F8F5"}}>#B8F8F5</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#397976", "color": "#FFF"}}>#397976</div></td>
                                            </tr>
                                            <tr>
                                                <td rowspan="2">
                                                    <button className='btn btn-ad-primary m-2'>Primary Button</button>
                                                    <button className='btn btn-ad-secondary m-2'>Button</button>
                                                </td>
                                                <td><div class="p-3 rounded-2" style={{"border" : "5px #9CF6F1 solid"}}>&nbsp;</div></td>
                                                <td><code>--ad-primary-border-subtle</code></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="fw-bold h2" style={{"text-align":"center", "color": "#44918D"}}>Main Title</div>
                                                    <div class="fw-bold h4" style={{"text-align":"center", "color": "#397976"}}>Subtitle</div>
                                                </td>
                                                <td><code>--ad-primary-text-emphasis</code></td>
                                            </tr>


                                            {/* Fei Hong Scarlet (Secondary) */}
                                            <tr>
                                                <td rowspan="6"><strong>Secondary, Danger —</strong> Main accent color; used for errors and dangerous actions.</td>
                                                <td colspan="2"><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#FF3E0D"}}>Fei Hong Scarlet (#FF3E0D)</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#FF5125"}}>#FF5125</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#E6380C"}}>#E6380C</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#FF653D"}}>#FF653D</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#CC320A"}}>#CC320A</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#FF7856"}}>#FF7856</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#B22B09", "color": "#FFF"}}>#B22B09</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#FF8B6E"}}>#FF8B6E</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#992508", "color": "#FFF"}}>#992508</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#FF9E86"}}>#FF9E86</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#801F06", "color": "#FFF"}}>#801F06</div></td>
                                            </tr>
                                            <tr>
                                                <td rowspan="2">
                                                    <button className='btn btn-ad-danger m-2'>Danger Button</button>
                                                    <button className='btn btn-ad-secondary m-2'>Button</button>
                                                </td>
                                                <td><div class="p-3 rounded-2" style={{"border" : "5px #FF7856 solid"}}>&nbsp;</div></td>
                                                <td>
                                                    <code>--ad-secondary-border-subtle</code><br/>
                                                    <code>--ad-danger-border-subtle</code>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="fw-bold h2" style={{"text-align":"center", "color": "#992508"}}>Main Title</div>
                                                    <div class="fw-bold h4" style={{"text-align":"center", "color": "#801F06"}}>Subtitle</div>
                                                </td>
                                                <td>
                                                    <code>--ad-secondary-text-emphasis</code><br/>
                                                    <code>--ad-danger-text-emphasis</code>
                                                </td>
                                            </tr>
                                            
                                            {/* Accent Green */}
                                            <tr>
                                                <td rowspan="6"><strong>Success —</strong>Complementary accent color; used for positive or successful actions and information.</td>
                                                <td colspan="2"><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#00A676", "color": "#FFF"}}>Accent Green (#00A676):</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#19AF84", "color": "#FFF"}}>#19AF84</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#00956A", "color": "#FFF"}}>#00956A</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#33B891", "color": "#FFF"}}>#33B891</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#00855E", "color": "#FFF"}}>#00855E</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#4DC19F"}}>#4DC19F</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#007453", "color": "#FFF"}}>#007453</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#66CAAD"}}>#66CAAD</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#006447", "color": "#FFF"}}>#006447</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#80D2BB"}}>#80D2BB</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#00533B", "color": "#FFF"}}>#00533B</div></td>
                                            </tr>
                                            <tr>
                                                <td rowspan="2">
                                                    <button className='btn btn-ad-success m-2'>Success Button</button>
                                                    <button className='btn btn-ad-secondary m-2'>Button</button>
                                                </td>
                                                <td><div class="p-3 rounded-2" style={{"border" : "5px #4DC19F solid"}}>&nbsp;</div></td>
                                                <td><code>--ad-success-border-subtle</code></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="fw-bold h2" style={{"text-align":"center", "color": "#006447"}}>Main Title</div>
                                                    <div class="fw-bold h4" style={{"text-align":"center", "color": "#00533B"}}>Subtitle</div>
                                                </td>
                                                <td><code>--ad-success-text-emphasis</code></td>
                                            </tr>

                                            {/* Accent Gold */}
                                            <tr>
                                                <td rowspan="6"><strong>Warning —</strong>Complementary accent color; used for non-destructive warning messages.</td>
                                                <td colspan="2"><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#FFB300"}}>Accent Yellow/Orange (#FFB300):</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#FFBB19"}}>#FFBB19</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#E6A100"}}>#E6A100</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#FFC233"}}>#FFC233</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#CC8F00"}}>#CC8F00</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#FFCA4D"}}>#FFCA4D</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#B27D00", "color": "#FFF"}}>#B27D00</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#FFD166"}}>#FFD166</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#996B00", "color": "#FFF"}}>#996B00</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#FFD980"}}>#FFD980</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#805A00", "color": "#FFF"}}>#805A00</div></td>
                                            </tr>
                                            <tr>
                                                <td rowspan="2">
                                                    <button className='btn btn-ad-warning m-2'>Warning Button</button>
                                                    <button className='btn btn-ad-secondary m-2'>Button</button>
                                                </td>
                                                <td><div class="p-3 rounded-2" style={{"border" : "5px #FFCA4D solid"}}>&nbsp;</div></td>
                                                <td><code>--ad-warning-border-subtle</code></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="fw-bold h2" style={{"text-align":"center", "color": "#996B00"}}>Main Title</div>
                                                    <div class="fw-bold h4" style={{"text-align":"center", "color": "#805A00"}}>Subtitle</div>
                                                </td>
                                                <td><code>--ad-warning-text-emphasis</code></td>
                                            </tr>

                                            {/* Accent Purple */}
                                            <tr>
                                                <td rowspan="6"><strong>Info —</strong> Contrasting accent color; used for neutral and informative content.</td>
                                                <td colspan="2"><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#8A56BF", "color": "#FFF"}}>Accent Color (Purple) (#8A56BF):</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#9768C7", "color": "#FFF"}}>#9768C7</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#7E52B1", "color": "#FFF"}}>#7E52B1</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#A479CE", "color": "#FFF"}}>#A479CE</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#7149A4", "color": "#FFF"}}>#7149A4</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#B08FCC", "color": "#FFF"}}>#B08FCC</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#684A8E", "color": "#FFF"}}>#684A8E</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#BE9ED7", "color": "#FFF"}}>#BE9ED7</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#5B407F", "color": "#FFF"}}>#5B407F</div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#C8A9DD", "color": "#FFF"}}>#C8A9DD</div></td>
                                                <td><div class="p-3 rounded-2" style={{"text-align":"center", "backgroundColor": "#543A6F", "color": "#FFF"}}>#543A6F</div></td>
                                            </tr>
                                            <tr>
                                                <td rowspan="2">
                                                    <button className='btn btn-ad-info m-2'>Special Button</button>
                                                    <button className='btn btn-ad-secondary m-2'>Button</button>
                                                </td>
                                                <td><div class="p-3 rounded-2" style={{"border" : "5px #9762C8 solid"}}>&nbsp;</div></td>
                                                <td><code>--ad-contrast-border-subtle</code></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="fw-bold h2" style={{"text-align":"center", "color": "#40136A"}}>Main Title</div>
                                                    <div class="fw-bold h4" style={{"text-align":"center", "color": "#361058"}}>Subtitle</div>
                                                </td>
                                                <td><code>--ad-contrast-text-emphasis</code></td>
                                            </tr>

                                            {/* Reference: https://getbootstrap.com/docs/5.3/customize/color/ */}

                                            <tr>
                                                <td rowspan="4">
                                                <strong>Light —</strong> Additional theme option for less contrasting colors.
                                                </td>
                                                <td class="ps-0">
                                                <div class="p-3 rounded-2 bg-light">&nbsp;</div>
                                                </td>
                                                <td>
                                                <code>--bs-light</code><br /><code>--bs-light-rgb</code>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <div class="p-3 rounded-2" style={{"background-color" : "var(--bs-light-bg-subtle)"}}>&nbsp;</div>
                                                </td>
                                                <td>
                                                <code>--bs-light-bg-subtle</code>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <div class="p-3 rounded-2" style={{"border" : "5px var(--bs-light-border-subtle) solid"}}>&nbsp;</div>
                                                </td>
                                                <td>
                                                <code>--bs-light-border-subtle</code>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <div class="py-3 fw-bold h5" style={{"color": "var(--bs-light-text-emphasis)"}}>Text</div>
                                                </td>
                                                <td>
                                                <code>--bs-light-text-emphasis</code>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td rowspan="4">
                                                <strong>Dark —</strong> Additional theme option for higher contrasting colors.
                                                </td>
                                                <td class="ps-0">
                                                <div class="p-3 rounded-2" style={{"background-color" : "#262523"}}>&nbsp;</div>
                                                </td>
                                                <td>
                                                <code>--bs-dark</code><br /><code>--bs-dark-rgb</code>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <div class="p-3 rounded-2" style={{"background-color" : "var(--bs-dark-bg-subtle)"}}>&nbsp;</div>
                                                </td>
                                                <td>
                                                <code>--bs-dark-bg-subtle</code>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <div class="p-3 rounded-2" style={{"border" : "5px var(--bs-dark-border-subtle) solid"}}>&nbsp;</div>
                                                </td>
                                                <td>
                                                <code>--bs-dark-border-subtle</code>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                <div class="py-3 fw-bold h5" style={{"color": "var(--bs-dark-text-emphasis)"}}>Text</div>
                                                </td>
                                                <td>
                                                <code>--bs-dark-text-emphasis</code>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Help;