import React from 'react';

import {useRef, useState, useEffect} from "react";
import {useNavigate} from 'react-router-dom';

import axios from '../api/axios';

const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
//const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}/;
const PWD_REGEX = /^.{8,}/;

const REGISTER_URL = '/register';

function Register() {
    // Set focus for user field
    const userRef = useRef();
    // Set focus for error
    const alertRef = useRef();

    const navigate = useNavigate();

    const [inputs, setInputs] = useState({});

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        const result = USER_REGEX.test(user);
        setValidName(result);
    }, [user]);

    useEffect(() => {
        const result = PWD_REGEX.test(pwd);
        setValidPwd(result);
        const match = pwd === matchPwd;
        setValidMatch(match);
    }, [pwd, matchPwd]);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd, inputs]);

    const updateInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Could further validate here
        try {
            await axios.post(REGISTER_URL,
                JSON.stringify({user, pwd, inputs}),
                {
                    headers: {'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            setInputs("");
            setUser("");
            setPwd("");
            setMatchPwd("");
            setSuccess(true);
            navigate('/login');
        }   
        catch (err) {
            if (!err?.response) {
                setErrMsg('No server response');
            } else if (err.response?.status === 409) {
                setErrMsg(`Unable to create user: ${err.response?.data}`);
                setValidName(false);
            }
            else {
                console.log(err)
                setErrMsg('Registration failure: ', JSON.stringify(err));
            }
        }
    }

    return (
        <div className="container">
            <div className="card">
                <div className="card mt-8">
                    <div className="card-header">
                        <h2>Create New Account</h2>
                    </div>
                    <div className="card-body">
                        <p ref={alertRef} role="alert" className={errMsg ? "alert alert-ad-danger" : "offscreen"}
                            aria-live="assertive">{errMsg}</p>
                        <p ref={alertRef} role="alert" className={success ? "alert alert-ad-success" : "offscreen"}
                        aria-live="assertive">Registration Successful!</p>

                        <form className="row g-3" onSubmit={handleSubmit}>

                            <div className="col-md-4">
                                <label htmlFor="inputUsername" className="form-label">Username</label>
                                <span className={validName ? "valid" : "offscreen"}> ✔</span>
                                <span className={validName || !user ? "offscreen" : "invalid"}> ❌</span>
                                <input type="text" id="inputUsername"
                                    className={validName || !user ? "form-control" : "form-control is-invalid"}
                                    ref={userRef} autoComplete="off"
                                    onChange={(e) => setUser(e.target.value)}
                                    required aria-invalid={validName ? "false" : true}
                                    aria-describedby="uidnote"
                                    onFocus={() => setUserFocus(true)}
                                    onBlur={() => setUserFocus(false)}
                                />
                                <p id="uidnote" className={userFocus && user && !validName ? "invalid-feedback" : "offscreen"}>
                                    4 to 24 characters.
                                    Must begin with a letter.
                                    Letters, numbers, underscores and hyphens allowed.
                                </p>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="email" className="form-label">Email Address</label>
                                <input type="email"
                                className="form-control"
                                required
                                name="email"
                                id="email"
                                onChange={updateInput}
                                value={inputs.email || ""} />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="phone" className="form-label">Phone Number</label>
                                <input type="tel"
                                className="form-control"
                                required
                                name="phone"
                                id="phone"
                                onChange={updateInput}
                                value={inputs.phone || ""} />
                            </div>
                            
                            <div className="col-md-6">
                                <label htmlFor="first" className="form-label">First Name</label>
                                <input type="text"
                                className="form-control"
                                required
                                name="first"
                                id="first"
                                onChange={updateInput}
                                value={inputs.first || ""} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="last" className="form-label">Last Name</label>
                                <input type="text"
                                className="form-control"
                                required
                                name="last"
                                id="last"
                                onChange={updateInput}
                                value={inputs.last || ""} />
                            </div>

                            <div className="col-12">
                                <label htmlFor="company" className="form-label">Company (optional)</label>
                                <input type="text"
                                className="form-control"
                                name="company"
                                id="company"
                                onChange={updateInput}
                                value={inputs.company || ""} />
                            </div>

                            <div className="col-12">
                                <label htmlFor="address" className="form-label">Address</label>
                                <input type="text"
                                className="form-control"
                                required
                                name="address" 
                                id="address"
                                onChange={updateInput}
                                value={inputs.address || ""} />
                            </div>

                            <div className="col-12">
                                <label htmlFor="address2" className="form-label">Address 2</label>
                                <input type="text"
                                className="form-control"
                                name="address2"
                                id="address2"
                                placeholder="Apartment, studio, or floor"
                                onChange={updateInput}
                                value={inputs.address2 || ""} />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="city" className="form-label">City</label>
                                <input type="text"
                                className="form-control"
                                required
                                name="city"
                                id="city"
                                onChange={updateInput}
                                value={inputs.city || ""} />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="state" className="form-label">State</label>
                                <input type="text"
                                className="form-control"
                                required
                                name="state"
                                id="state"
                                onChange={updateInput}
                                value={inputs.state || ""} />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="zip" className="form-label">Zip</label>
                                <input type="text"
                                className="form-control"
                                required
                                name="zip"
                                id="zip"
                                onChange={updateInput}
                                value={inputs.zip || ""} />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="inputPassword" className="form-label">Password</label>
                                <span className={validPwd ? "valid" : "offscreen"}> ✔</span>
                                <span className={validPwd || !pwd ? "offscreen" : "invalid"}> ❌</span>
                                <input 
                                    type="password"
                                    className={validPwd || !pwd ? "form-control" : "form-control is-invalid"}
                                    id="inputPassword" 
                                    onChange={(e) => setPwd(e.target.value)}
                                    required
                                    aria-invalid={validPwd ? "false" : true}
                                    aria-describedby="pwdnote"
                                    onFocus={() => setPwdFocus(true)}
                                    onBlur={() => setPwdFocus(false)}
                                />
                                <p id="pwdnote" className={pwdFocus && !validPwd ? "invalid-feedback" : "offscreen"}>
                                    Must be at least 8 characters.
                                </p>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputPasswordConfirm" className="form-label">Password Confirmation</label>
                                <span className={validMatch && matchPwd ? "valid" : "offscreen"}> ✔</span>
                                <span className={validMatch || !pwd ? "offscreen" : "invalid"}> ❌</span>
                                <input 
                                    type="password"
                                    className={validMatch || !pwd ? "form-control" : "form-control is-invalid"}
                                    id="inputPasswordConfirm" 
                                    onChange={(e) => setMatchPwd(e.target.value)}
                                    required
                                    aria-invalid={validMatch ? "false" : true}
                                    aria-describedby="confirmnote"
                                    onFocus={() => setMatchFocus(true)}
                                    onBlur={() => setMatchFocus(false)}
                                />
                                <p id="confirmnote" className={matchFocus && !validMatch ? "invalid-feedback" : "offscreen"}>
                                    Passwords must match.
                                </p>
                                
                            </div>

                            <div className="col-12">
                                <button disabled={!validName || !validPwd || !validMatch} type="submit" className="btn btn-ad-primary">Register</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;